interface RatingOption {
  value: number;
  label: string;
}

const defaultRatingOptions: RatingOption[] = [
  { value: 0, label: 'Not Assessed'},
  { value: -1, label: 'No Risk' },
  { value: 1, label: 'Low' },
  { value: 2, label: 'Low-medium' },
  { value: 3, label: 'Medium' },
  { value: 4, label: 'Medium-High' },
  { value: 5, label: 'High' }
];

const ghgRatingCategories: RatingOption[] = [
  { value: 0, label: 'N/A' },
  { value: 1, label: 'A+' },
  { value: 2, label: 'A' },
  { value: 3, label: 'B+' },
  { value: 4, label: 'B' },
  { value: 5, label: 'C+' },
  { value: 6, label: 'C' },
  { value: 7, label: 'D+' },
  { value: 8, label: 'D' },
  { value: 9, label: 'E+' },
  { value: 10, label: 'E' }
];

export const getRatingOptions = (): RatingOption[] => {
  return defaultRatingOptions;
};

export const getGHGRatingOptions = (): RatingOption[] => {
  return ghgRatingCategories;
};

export const ratingSheetsUrl =
  'https://docs.google.com/spreadsheets/d/1aznrZPtHIQVi5_R_84kJOGqx3VImze3tGq_aPG2enxU/edit#gid=738692414';

  const ratingReasonFields = {
    change_date: 'date',
    old_rating: 'oldRating',
    new_rating: 'newRating',
    type: 'type',
    reason: 'reason'
  };

  export const getRatingReasonFieldNames = (key: string): string => {
    return ratingReasonFields[key] || '';
  };
  